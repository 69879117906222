<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid slice"
  >
    <defs>
      <path
        d="M638,739.400002 L673.493805,739.400002 L673.493805,621.763031 C674.027135,599.800466 668.111501,582.975871 655.746902,571.289246 C643.382304,559.60262 638.800003,550.254832 642,543.24588 L843.335693,543.24588 L843.335693,347.813354 C842.711905,334.071119 835.333342,327.200001 821.200005,327.200001 L821.200005,317.600001"
        id="path-1"
      ></path>
      <filter
        x="-9.5%"
        y="-4.6%"
        width="119.0%"
        height="109.2%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.00000012"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feMorphology
          radius="3.00000012"
          operator="erode"
          in="SourceAlpha"
          result="shadowInner"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowInner"
          result="shadowInner"
        ></feOffset>
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        ></feComposite>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.5, 0.5), rotate(90), scale(1, 0.9806), translate(-0.5, -0.5)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
        <stop
          stop-color="#FFFFFF"
          stop-opacity="0.466182255"
          offset="100%"
        ></stop>
      </radialGradient>
      <path
        d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0"
        id="path-4"
      ></path>
      <filter
        x="-101.6%"
        y="-70.7%"
        width="303.1%"
        height="248.3%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Cosmopolitan"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="path-cosmopolitan">
        <g id="Route" stroke-linecap="round" stroke-linejoin="round">
          <path
            fill="black"
            fill-opacity="1"
            filter="url(#filter-2)"
            xlink:href="#path-1"
            class="path-glow"
          ></path>
          <use
            stroke="#FFFFFF"
            stroke-width="6.00000024"
            xlink:href="#path-1"
            class="path-line"
          ></use>
        </g>
        <g id="Ending-Point" transform="translate(622.8, 724)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="14.6000011"
            r="4.80000019"
          ></circle>
        </g>
        <image
          id="Bitmap"
          x="496"
          y="673"
          width="157"
          height="141"
          href="@/assets/images/icons/end.png"
        ></image>
        <g id="Starting-Point" transform="translate(806.8, 290)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="27.6000011"
            r="4.80000019"
          ></circle>
          <g id="Fill-1">
            <use
              fill="black"
              fill-opacity="1"
              filter="url(#filter-5)"
              xlink:href="#path-4"
            ></use>
            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
          </g>
        </g>
        <text
          id="The-Cosmopolitan"
          font-family="ArtegraSans-Regular, Artegra Sans"
          font-size="14.4000006"
          font-weight="normal"
          line-spacing="15.6000006"
          fill="#FFFFFF"
        >
          <tspan x="849" y="322">The Cosmopolitan</tspan>
        </text>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="899"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="899"
          width="1"
          height="1"
        ></rect>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CosmopolitanPath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    var pathGlow = document.querySelector(".path-glow");
    var lengthGlow = pathGlow.getTotalLength();
    pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    pathGlow.style.strokeDashoffset = lengthGlow;
    pathGlow.getBoundingClientRect();
    pathGlow.style.transition = pathGlow.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    pathGlow.style.strokeDashoffset = "0";
  },
};
</script>

<style lang="scss" scoped>
svg {
  background-image: url("~@/assets/maps/bg-venetian-route.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>